<template>
  <div>
    <v-spacer></v-spacer>
    <v-card class="mt-3">
      <v-card-title>
        <v-text-field v-model="search" placeholder="Search..."></v-text-field>
      </v-card-title>
      <v-data-table v-if="articles" :headers="headers" :items="articles.records" :loading="isLoading" :search="search" class="elevation-1" transition="scale-transition">
        <template v-slot:top>
          <v-btn class="mt-2" absolute right color="primary" small @click="$router.push('/admin/article/0/edit')">New Article</v-btn>
        </template>
        <template v-slot:item.featurePicture="{ item }">
          <v-img :aspect-ratio="16 / 9" :src="item.featurePicture" width="75"></v-img>
        </template>
        <template v-slot:item.createdAt="{ item }">
          <h5 color="primary">{{ item.createdAt | getFormattedDate }}</h5>
        </template>
        <template v-slot:item.updatedAt="{ item }">
          <h5 color="primary">{{ item.updatedAt | getFormattedDate }}</h5>
        </template>
        <template v-slot:item.title="{ item }">
          <h5 color="primary">
            <router-link :to="'/admin/article/' + item.id">{{ item.title }}</router-link>
          </h5>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon color="primary" small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon color="error" small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { gql } from "apollo-boost";

export default {
  data() {
    return {
      headers: [
        { text: "Featured", value: "featurePicture", sortable: false, align: "start" },
        { text: "Title", value: "title", sortable: false, align: "start" },
        { text: "Created Date", value: "createdAt" },
        { text: "Updated Date", value: "updatedAt" },
        { text: "", value: "actions", sortable: false, align: "right" },
      ],
      search: "",
      totalRecords: 0,
      articles: [],
      isLoading: false,
    };
  },
  apollo: {
    articles: {
      query: gql`
        query Articles($limit: Int!, $skip: Int!, $query: JSON!) {
          articles(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              title
              content
              featureContent
              featurePicture
              headerPicture
              createdBy
              createdAt
              updatedAt
            }
            count
          }
        }
      `,
      variables() {
        const self = this;
        const limit = 9999;
        const skip = 0;
        return {
          limit: limit,
          skip: skip,
          query: {},
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
  },
  methods: {
    editItem(item) {
      this.$router.push(`/admin/article/${item.id}/edit`);
    },

    deleteItem(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#c79477",
        cancelButtonColor: "#707077",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.value) {
          try {
            const response = await this.$apollo.mutate({
              mutation: gql`
                mutation($id: ID!) {
                  deleteArticle(id: $id) {
                    id
                    deleted
                  }
                }
              `,
              variables: {
                id: item.id,
              },
            });

            if (response) {
              this.$swal({
                title: "Success",
                text: "Article was successfully deleted",
                icon: "success",
                confirmButtonColor: "#c79477",
              });
              this.$router.go(this.$route.currentRoute);
            }
          } catch (e) {
            this.$swal({
              title: "Error",
              text: e.mesage,
              icon: "error",
              confirmButtonColor: "#c79477",
            });
            console.log(`Login Failed ${JSON.stringify(e)}`);
            self.errorMsg = e.message;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
