var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-spacer'),_c('v-card',{staticClass:"mt-3"},[_c('v-card-title',[_c('v-text-field',{attrs:{"placeholder":"Search..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.articles)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.articles.records,"loading":_vm.isLoading,"search":_vm.search,"transition":"scale-transition"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-btn',{staticClass:"mt-2",attrs:{"absolute":"","right":"","color":"primary","small":""},on:{"click":function($event){return _vm.$router.push('/admin/article/0/edit')}}},[_vm._v("New Article")])]},proxy:true},{key:"item.featurePicture",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"aspect-ratio":16 / 9,"src":item.featurePicture,"width":"75"}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('h5',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm._f("getFormattedDate")(item.createdAt)))])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('h5',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm._f("getFormattedDate")(item.updatedAt)))])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('h5',{attrs:{"color":"primary"}},[_c('router-link',{attrs:{"to":'/admin/article/' + item.id}},[_vm._v(_vm._s(item.title))])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}],null,false,2590619226)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }